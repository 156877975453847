var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLBASEINFO" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.updateMode
                            ? _c("c-btn", {
                                attrs: { label: "LBLREMOVE", icon: "remove" },
                                on: { btnClicked: _vm.deleteData },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-plant", {
                          attrs: {
                            editable: _vm.editable && !_vm.updateMode,
                            required: true,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-field", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            data: _vm.data,
                            deptValue: "deptCd",
                            type: "dept_user",
                            label: "작성자",
                            name: "userId",
                          },
                          model: {
                            value: _vm.data.userId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "userId", $$v)
                            },
                            expression: "data.userId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "측정일",
                            name: "measureDt",
                          },
                          model: {
                            value: _vm.data.measureDt,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "measureDt", $$v)
                            },
                            expression: "data.measureDt",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "환경기술인 의견",
                            name: "envRemark",
                          },
                          model: {
                            value: _vm.data.envRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "envRemark", $$v)
                            },
                            expression: "data.envRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.popupParam.envWaterSelfMeasureMstId,
            expression: "popupParam.envWaterSelfMeasureMstId",
          },
        ],
        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
      },
      [
        _c(
          "c-table",
          {
            ref: "grid",
            attrs: {
              title: "측정위치별 검사결과",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              gridHeight: _vm.gridHeight,
              editable: _vm.editable,
              columnSetting: false,
              filtering: false,
              pagePerRow: { pageNumber: 20 },
            },
          },
          [
            _c(
              "template",
              { slot: "suffixTitle" },
              [
                _vm._v("     "),
                _c(
                  "font",
                  {
                    staticStyle: {
                      "font-size": "0.85em",
                      "font-weight": "300",
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons bg-orange-4 text-orange-4",
                      },
                      [_vm._v("check")]
                    ),
                    _vm._v(" 내부관리기준 초과"),
                  ]
                ),
              ],
              1
            ),
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        isFlag: true,
                        falseLabel: "미측정 위치 제외",
                        trueLabel: "미측정 위치 제외",
                        name: "existData",
                        addClasses: "tableCheckbox-top",
                      },
                      on: { input: _vm.getList },
                      model: {
                        value: _vm.existData,
                        callback: function ($$v) {
                          _vm.existData = $$v
                        },
                        expression: "existData",
                      },
                    }),
                    _vm.editable && _vm.updateMode
                      ? _c("c-btn", {
                          attrs: { label: "엑셀업로드", icon: "upload" },
                          on: { btnClicked: _vm.excelUploadData },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }