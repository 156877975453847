<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && updateMode"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-plant :editable="editable && !updateMode" :required="true" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <c-field
                  :required="true"
                  :editable="editable"
                  :data="data"
                  deptValue="deptCd"
                  type="dept_user"
                  label="작성자"
                  name="userId"
                  v-model="data.userId">
                </c-field>
              </div>
              <div class="col-2">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  label="측정일"
                  name="measureDt"
                  v-model="data.measureDt"
                />
              </div>
              <!-- <div class="col-2">
                <c-select
                  type="edit"
                  :editable="editable"
                  codeGroupCd="WEATHER_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="날씨"
                  name="weatherCd"
                  v-model="data.weatherCd">
                </c-select>
              </div>
              <div class="col-2">
                <c-text
                  :editable="editable"
                  label="온도"
                  suffix="℃"
                  name="temperature"
                  v-model="data.temperature">
                </c-text>
              </div> -->
              <!-- <div class="col-3">
                <c-select
                  type="edit"
                  :required="true"
                  :editable="editable"
                  codeGroupCd="WATER_MEASURE_TYPE_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="측정구분"
                  name="waterMeasureTypeCd"
                  v-model="data.waterMeasureTypeCd">
                </c-select>
              </div> -->
              <!-- <div class="col-3">
                <c-text
                  :editable="editable"
                  label="환경기술인"
                  name="envUserName"
                  v-model="data.envUserName">
                </c-text>
              </div> -->
              <div class="col-12">
                <c-text
                  :editable="editable"
                  label="환경기술인 의견"
                  name="envRemark"
                  v-model="data.envRemark">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-show="popupParam.envWaterSelfMeasureMstId">
      <c-table
        ref="grid"
        title="측정위치별 검사결과"
        :columns="grid.columns"
        :data="grid.data"
        :gridHeight="gridHeight"
        :editable="editable"
        :columnSetting="false"
        :filtering="false"
        :pagePerRow="{pageNumber: 20}"
      >
        <!-- 버튼 영역 -->
        <template slot="suffixTitle">
          <!-- &nbsp;&nbsp;&nbsp;&nbsp;<font style="font-size:0.85em;font-weight:300;"><i class="material-icons bg-red-6 text-red-6">check</i> 법적기준 초과&nbsp;&nbsp;&nbsp;<i class="material-icons bg-orange-4 text-orange-4">check</i> 내부관리기준 초과</font> -->
          &nbsp;&nbsp;&nbsp;&nbsp;<font style="font-size:0.85em;font-weight:300;"><i class="material-icons bg-orange-4 text-orange-4">check</i> 내부관리기준 초과</font>
        </template>
        <template slot="table-button">
          <q-btn-group outline >
            <c-checkbox
              :isFlag="true"
              falseLabel="미측정 위치 제외"
              trueLabel="미측정 위치 제외"
              name="existData"
              addClasses="tableCheckbox-top"
              v-model="existData"
              @input="getList"
            />
            <c-btn
              v-if="editable && updateMode"
              label="엑셀업로드"
              icon="upload"
              @btnClicked="excelUploadData" />
          </q-btn-group>
        </template>
      </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'self-water-measure-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envWaterSelfMeasureMstId: '',
          plantCd: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      existData: 'N',
      saveUrl: transactionConfig.env.water.self.measure.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      headerUrl: '',
      listUrl: '',
      saveMeasurelocUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      envWaterMstInspectItemIds: [],
      data: {
        plantCd: null,  // 사업장코드
        envWaterSelfMeasureMstId: '',  // 수질 자가측정 일련번호
        measureDt: '',  // 측정일자
        userId: '',  // 작성자
        deptCd: '',  // 작성부서
        weatherCd: null,  // 날씨
        temperature: '',  // 온도
        waterMeasureTypeCd: 'WMTC000001',  // 수질 자가측정구분
        measureVendorName: '',  // 측정업체 또는 측정자
        envUserName: '',  // 환경기술인
        envRemark: '',  // 환경기술인 의견
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'envWaterMstMeasurelocName',
            field: 'envWaterMstMeasurelocName',
            label: '측정위치 ',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
            fix: true,
          },
        ],
        data: [],
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? String(this.contentHeight - 300) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.water.self.measure.get.url;
      this.headerUrl = selectConfig.env.water.self.measureloc.header.url;
      this.listUrl = selectConfig.env.water.self.measureloc.list.url;
      this.insertUrl = transactionConfig.env.water.self.measure.insert.url;
      this.updateUrl = transactionConfig.env.water.self.measure.update.url;
      this.deleteUrl = transactionConfig.env.water.self.measure.delete.url;
      this.saveMeasurelocUrl = transactionConfig.env.water.self.measureloc.save.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.envWaterSelfMeasureMstId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envWaterSelfMeasureMstId: this.popupParam.envWaterSelfMeasureMstId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.getHeader();
        },);
      } else {
        this.data.measureDt = this.$comm.getToday();
        this.data.userId = this.$store.getters.user.userId;
        this.data.deptCd = this.$store.getters.user.deptCd;
        this.updateMode = false;
      }
    },
    getHeader() { 
      this.$http.url = this.headerUrl;
      this.$http.param = {envWaterSelfMeasureMstId: this.data.envWaterSelfMeasureMstId, plantCd: this.data.plantCd}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.envWaterMstInspectItemIds = [];
        this.$_.forEach(_result.data, item => {
          this.envWaterMstInspectItemIds.push(item.envWaterMstInspectItemId);
          this.grid.columns.push(
            {
              name: item.envWaterMstInspectItemId,
              field: item.envWaterMstInspectItemId,
              label: item.envWaterMstInspectItemName + '<br>',
              align: 'center',
              type: 'number',
              style: 'width: 90px',
              sortable: false,
              colorClass: ['R,bg-red-6 text-white','I,bg-orange-4 text-white'],
              colorTarget: item.envWaterMstInspectItemId + '001',
              // helpcomment: '법적기준: ' + item.legalStandard + '<br>내부기준: ' + item.innerStandard,
              helpcomment: '내부기준: ' + item.innerStandard,
            }
          )
        });
        this.getList();
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.param = {
        envWaterSelfMeasureMstId: this.data.envWaterSelfMeasureMstId, 
        plantCd: this.data.plantCd,
        envWaterMstInspectItemIds: this.envWaterMstInspectItemIds,
        existData: this.existData
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveInfo() {
      if (this.popupParam.envWaterSelfMeasureMstId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info', // success / info / warning / error
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.envWaterSelfMeasureMstId = result.data.envWaterSelfMeasureMstId;
      if (this.mappingType == 'PUT') {
        this.saveRow();
      } else {
        this.getDetail();
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
    },
    saveRow() {
      let saveData = this.grid.data;
      if(saveData.length > 0) {
        this.$_.forEach(saveData, _item => {
          let WaterSelfMeasureInspectItemLists = [];
          _item.regUserId = this.$store.getters.user.userId;
          _item.chgUserId = this.$store.getters.user.userId;
          var keys = Object.keys(_item);
          for (var i=0; i<keys.length; i++) {
            var key = keys[i];
            if (key.length == 10 && key.indexOf('WMI') > -1 && parseFloat(_item[key]) > 0) {
              WaterSelfMeasureInspectItemLists.push({
                envWaterSelfMeasureMstId: _item.envWaterSelfMeasureMstId,
                envWaterMstMeasurelocId: _item.envWaterMstMeasurelocId,
                envWaterMstInspectItemId: key,
                measure: parseFloat(_item[key])
              });
            }
          }
          _item.waterSelfMeasureInspectItems = WaterSelfMeasureInspectItemLists;
        });

        this.$http.url = this.saveMeasurelocUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.envWaterSelfMeasureMstId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    excelUploadData() {
      this.popupOptions.title = '수질-자가측정결과 업로드';
      this.popupOptions.param = {
        envWaterSelfMeasureMstId: this.popupParam.envWaterSelfMeasureMstId,
      }
      this.popupOptions.target = () => import(`${'./selfWaterMeasureExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.plantCd = this.data.plantCd;
          item.envWaterSelfMeasureMstId = this.popupParam.envWaterSelfMeasureMstId;
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
          
          let WaterSelfMeasureInspectItemLists = [];
          var keys = Object.keys(item);
          for (var i=0; i<keys.length; i++) {
            var key = keys[i];
            if (key.length == 10 && key.indexOf('WMI') > -1 && parseFloat(item[key]) !== undefined) {
              WaterSelfMeasureInspectItemLists.push({
                envWaterSelfMeasureMstId: item.envWaterSelfMeasureMstId,
                envWaterMstMeasurelocName: item.envWaterMstMeasurelocName,
                envWaterMstInspectItemId: key,
                measure: parseFloat(item[key])
              });
            }
          }
          item.waterSelfMeasureInspectItems = WaterSelfMeasureInspectItemLists;
        })

        this.$http.url = this.saveMeasurelocUrl + '/excel';
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    }
  }
};
</script>